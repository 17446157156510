import { getDotComRoutingKey, getManifestOrDeployedPath } from "./DotComUtils";

let deployedEnv = getManifestOrDeployedPath();

export const DOTCOM_DEFAULT_SETTINGS = {
    transmitConfigs: {
      URL: '/Proxy/TS',
      APP_ID: 'web',
      BASE_API_URL: '',
      policyIds: {
        POLICY: "password_login"
      },
      policyParams: {
        clientId: "OLB",
        visitorId: window.visitor ? window.visitor?.getMarketingCloudVisitorID() : "",
        routingKey: getDotComRoutingKey(),
        callRememberMeSBApi: true,
        native: true,
        passwordless: window.location.host?.startsWith('emp') ? true : false,
        kba: true,
        tenantId: "USB",
        appId: "DOTCOM",
        channelId: "web",
        appVersion: "1.0",
        ver: 1,
        eml: true,
      }
    },
    throttlePercentage: {
        "EnrollmentThrottle": "100"
    },
    constants:{
      "SKIP_INIT_APPD": true
    },
    urls: {
        FARM_SIGN_UP_PATH:"",
        BASE_URL: deployedEnv,
        JS_LOGGER_DOMAIN: window.location.host.startsWith('emp') || window.location.host.startsWith('it') ? deployedEnv : '',
        loginAssistance: {
        FORGOT_ID: deployedEnv + '/OLS/LoginAssistant/RetriveId',
        RESET_PASSWORD: deployedEnv + '/digital/loginhelp/',
        UPDATE_CREDENTIALS_API: "https://auth-credentials-dev.us.bank-dns.com/digital-auth/services/credentials/v1/update",
        LOGIN_HELP: deployedEnv + '/digital/loginhelp/',
        CREATE_USERID_PASSWORD: deployedEnv + '/Auth/EnrollmentDesktop/Verification',
        FORGOT_SHIELD_ANSWER: deployedEnv + '/digital/loginhelp/IDshield/',
        IDENTIFYUSER: deployedEnv + '/digital/loginhelp/Identify/',
        STATE_FARM_CUSTOMER: deployedEnv + '/OLS/Public/Enrollment/Index?isPartnerOLB=true',
        PCI_USER_RESET_PASSWORD: deployedEnv + '/digital/loginhelp/?chgusr=true'
        },
        dashboard: {
            CUST_HUB_LANDING_PAGE: deployedEnv + '/Auth/CustHubLandingPage.aspx'
        },
        routerAppURL: deployedEnv + "/digital/servicing/feature-router/route",
        bauCustomerDashboardUrl: deployedEnv + "/digital/servicing/customer-dashboard",
        routerappShellCDUrl: deployedEnv + "/digital/servicing/shellapp/#/customer-dashboard",
        bauAccountDashboardUrl: deployedEnv + "/digital/servicing/account-dashboard",
        routerappShellADUrl: deployedEnv + "/digital/servicing/shellapp/#/account-dashboard",
        loginStandaloneURL: deployedEnv + "/auth/login/"
    },
    regex: {
      PASSWORD: "^.{8,24}$"
    },
    displayFields: {
      REMEMBER_ME: "show",
      ENROLLMENT_LINK: "show",
      ACCOUNT_TYPE_DROPDOWN: "show",
      COMPANY_ID: "hide",
      LOGIN_HELP: "show",
      FORGOT_ID: "hide",
      RESET_PASSWORD: "hide",
      FARM_SIGN_UP: "hide",
      MANAGE_ERROR_MESSAGES: "hide"
    },
    lang: {
        "en-us" : {
          labels: {
              HEADER_TAG: "h2"
          }
        },
        "es-us" : {
          labels: {
              HEADER_TAG: "h2"
          }
        }
    }
}

export const DOTCOM_DROPDOWN_REDIRECT_SETTINGS = {
    ...DOTCOM_DEFAULT_SETTINGS,
    urls: {
      ...DOTCOM_DEFAULT_SETTINGS.urls,
      login: {
          CNC_BANKING_URL: "https://www.usbank.com/corporate-and-commercial-banking/access-accounts.html"
      },
    },
    displayFields: {
      ...DOTCOM_DEFAULT_SETTINGS.displayFields,
      ACCOUNT_TYPE_DROPDOWN: "hide",
      CNC_BANKING: "show"
    },
    lang: {
        "en-us" : {
            labels: {
                ...DOTCOM_DEFAULT_SETTINGS.lang["en-us"].labels,
                CNC_BANKING_LINK_LABEL: "Corporate & Commercial banking login",
                GET_LOGIN_HELP: "Forgot username or password",
                CREATE_USERNAME_PASSWORD: "Enroll in online banking"
            }
          },
        "es-us" : {
            labels: {
                ...DOTCOM_DEFAULT_SETTINGS.lang["es-us"].labels,
                CNC_BANKING_LINK_LABEL: "El inicio de sesión Corporativo y Comercial",
                GET_LOGIN_HELP: "Olvidé nombre de usuario o contraseña",
                CREATE_USERNAME_PASSWORD: "Inscríbete en la banca en línea"
            }
          }
    }
}
